const handleDarkmode = (e) => {
  var darkModeOn = e.matches;
  var largeFavicons = document.querySelectorAll('link[rel="icon"]');
  if (!largeFavicons) {
    return;
  }
  if (darkModeOn) {
    largeFavicons.forEach(ele => {
      ele.href = require("./src/assets/images/favicon-light-mode.svg").default
    })
  } else {
    largeFavicons.forEach(ele => {
      ele.href = require("./src/assets/images/favicon-dark-mode.svg").default
    })
  }
}

exports.onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    darkModeMediaQuery.addListener(e => {
      handleDarkmode(darkModeMediaQuery)
    })
    handleDarkmode(darkModeMediaQuery)
  
    const urlParams = new URLSearchParams(location.search);
    const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
    let foundParams = {};

    utmParams.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        foundParams[param] = value;
      }
    });

    if (Object.keys(foundParams).length > 0) {
      sessionStorage.setItem("utmParams", JSON.stringify(foundParams));
    }
  }
}